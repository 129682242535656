
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmCountry from '@/components/shared/TmCountry.vue'

export default defineComponent({
  name: 'ServicesNumbersLabelModal',
  components: { TmCountry, TmFormLine, TmButton, TmModal },
  props: {
    isEdit: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  setup(props) {
    const country = ref({ id: 'uk', name: '+18336557665' })
    const labelVal = ref(props.label)

    return {
      country,
      labelVal,
    }
  },
})
