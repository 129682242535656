import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "servicesNumbersLabel",
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        label: "Number",
        class: "mb-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_country, { country: _ctx.country }, null, 8, ["country"])
        ]),
        _: 1
      }),
      _createVNode(_component_tm_form_line, { label: "Label name" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.labelVal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.labelVal) = $event)),
            placeholder: "Enter label name",
            autofocus: ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        color: "blue",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isEdit ? 'Save' : 'Add'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}